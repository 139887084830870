// extracted by mini-css-extract-plugin
export var container = "_AdsFeatureSummary-module--container--XStoZ";
export var content = "_AdsFeatureSummary-module--content--2lwW-";
export var contentLeft = "_AdsFeatureSummary-module--contentLeft---Ljva";
export var contentRight = "_AdsFeatureSummary-module--contentRight--kPvck";
export var item = "_AdsFeatureSummary-module--item--qoAMa";
export var itemText = "_AdsFeatureSummary-module--itemText--1crmV";
export var itemTextstyle = "_AdsFeatureSummary-module--itemTextstyle--IL8ij";
export var limitWidthOnLargeScreens = "_AdsFeatureSummary-module--limitWidthOnLargeScreens--dMCmd";
export var list = "_AdsFeatureSummary-module--list--3nKMA";
export var main = "_AdsFeatureSummary-module--main--F3cnu";
export var responsiveImage = "_AdsFeatureSummary-module--responsiveImage--wtqWg";
export var star = "_AdsFeatureSummary-module--star--zXrBG";
export var subTitle = "_AdsFeatureSummary-module--subTitle--rP8yM";
export var title = "_AdsFeatureSummary-module--title--laCTp";
export var titleSection = "_AdsFeatureSummary-module--titleSection--r0+BZ";