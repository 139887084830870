import React from "react";
import * as styles from "./_AdsFeatureSummary.module.scss";
import classNames from "classnames";
import { TypographyV2 } from "@components/index";
import { Colors } from "@helpers/Colors";

type Props = {
	Img: () => JSX.Element;
	title: string;
	subtitle?: string;
	list: string[];
	color: Colors;
	link: string;
	imageRight?: boolean;
	imageLeft?: boolean;
};

const AdsFeatureSummary = ({
	Img,
	title,
	subtitle,
	list,
	link,
	color,
	imageRight,
	imageLeft,
}: Props) => {
	let contentClassName = styles.main;
	if (imageLeft) {
		contentClassName = classNames(contentClassName, styles.contentRight);
	}
	if (imageRight) {
		contentClassName = classNames(contentClassName, styles.contentLeft);
	}

	return (
		<div id={link} className={styles.container} style={{
			overflowX: 'hidden'
		}}>
			<div className={contentClassName}>
				<div className={styles.responsiveImage}>
					<Img />
				</div>
				<div className={styles.titleSection}>
					<TypographyV2
						className={styles.title}
						variant="HEADING_2"
						tabletVariant="HEADING_3"
						mobileVariant="HEADING_4"
						weight="MEDIUM"
						color={color}
					>
						{title}
					</TypographyV2>
				</div>
				<div className={styles.content}>
					{subtitle && (
						<TypographyV2
							className={styles.subTitle}
							variant="BODY_TEXT_EXTRA_LARGE"
							tabletVariant="BODY_TEXT_LARGE"
							mobileVariant="BODY_TEXT_MEDIUM"
							weight="REGULAR"
							color="text-secondary"
						>
							{subtitle}
						</TypographyV2>
					)}
					<div className={styles.list}>
						{list.map((obj, index) => {
							return (
								<div key={index} className={styles.item}>
									<div className={styles.star}>
										<StarPattern />
									</div>
									<div className={styles.itemText}>
										<TypographyV2
											variant="BODY_TEXT_EXTRA_LARGE"
											tabletVariant="BODY_TEXT_LARGE"
											mobileVariant="BODY_TEXT_LARGE"
											color="text-helper"
											weight="REGULAR"
											className={styles.itemTextstyle}
										>
											{obj}
										</TypographyV2>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdsFeatureSummary;

const StarPattern = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 2L14.012 7.23109C14.294 7.96432 14.435 8.33093 14.6542 8.63931C14.8486 8.91262 15.0874 9.15141 15.3607 9.34575C15.6691 9.56503 16.0357 9.70603 16.7689 9.98804L22 12L16.7689 14.012C16.0357 14.294 15.6691 14.435 15.3607 14.6542C15.0874 14.8486 14.8486 15.0874 14.6542 15.3607C14.435 15.6691 14.294 16.0357 14.012 16.7689L12 22L9.98804 16.7689C9.70603 16.0357 9.56503 15.6691 9.34575 15.3607C9.15141 15.0874 8.91262 14.8486 8.63931 14.6542C8.33093 14.435 7.96432 14.294 7.23109 14.012L2 12L7.23108 9.98804C7.96431 9.70603 8.33093 9.56503 8.63931 9.34575C8.91262 9.15141 9.15141 8.91262 9.34575 8.63931C9.56503 8.33093 9.70603 7.96431 9.98804 7.23108L12 2Z"
				stroke="#D35D08"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
